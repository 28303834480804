import {axiosApiInstance} from "./HttpService";
import {IPeriodIndicator} from "../models/IBarChartData";
import {Chart} from "chart.js";
import {EmptyObject} from "chart.js/types/basic";

export class DashboardService{

    public static getDashboard(startDate:string,endDate:string,currency:string,accumulationCriteria:string){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Dashboard/getDashboard`;
        return axiosApiInstance.post(dataURL,{
            startDate: startDate,
            endDate: endDate,
            currency:currency,
            accumulationCriteria:accumulationCriteria
        });
    }


    public static getSettlementDashboard(startDate:string,endDate:string,currency:string,accumulationCriteria:string){
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementDashboard/getDashboard`;
        return axiosApiInstance.post(dataURL,{
            startDate: startDate,
            endDate: endDate,
            currency:currency,
            accumulationCriteria:accumulationCriteria
        });
    }

    public static createIPeriodIndicator(arrayInitValue: number[]): IPeriodIndicator {
        return {
            titleLabel: "",
            total: 0,
            periodTotals: arrayInitValue,
            countTotals: arrayInitValue,
            promTotals: arrayInitValue,
            chargeAmountTotals: arrayInitValue,
            contrapartAmountTotals: arrayInitValue,
            taxAmountTotals: arrayInitValue,
            tariffAmountTotals: arrayInitValue,
            netAmountTotals: arrayInitValue,
            credVouchAmountTotals: arrayInitValue,
            campainCountTotals: arrayInitValue,
            campainAmountTotals: arrayInitValue,
            vouchCountTotals: arrayInitValue,
            vouchAmountTotals: arrayInitValue,
            rows: [{
                label: "",
                color: "",
                values: [],
                count:[]
            }],
            labels: [],
            currentPeriodLabel: "-",
            lastPeriodLabel: "-"
        }
    }

    public static getBarChartOptions() {
        return {
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    bodyFont: {
                        size: 15
                    }
                },
            },
            dataLabels: {
                enabled: true,
                font: {
                    size: 15
                }
            },
            barPercentage: 0.8,
            scales: {
                y: {
                    display: true,
                    grid: {
                        display: true
                    }
                },
                x: {
                    grid: {
                        display: false
                    },
                    maxBarThickness:50,
                    paddingTop: 40,
                    ticks: {
                        height: 150,
                        font: {
                            size: 15,
                            height: 5.0,
                            family: " -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'"
                        }
                    }
                }
            }
        }
    }
}

export const innerVertBarText={
    id:'innerVertBarText',
    afterDatasetsDraw(chart: Chart, args: EmptyObject, options: any) {
        const{ ctx,data,chartArea:{right},scales:{x,y}}=chart;
        for (let i = 0; i < data.datasets.length; i++) {
            data.datasets[i].data.forEach((dataPoint,index)=>{
                ctx.font='22px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

                var text=Intl.NumberFormat('es-UY', { maximumFractionDigits:0 }).format(Number(dataPoint));
                var xPosition=chart.getDatasetMeta(i).data[index].x;
                // @ts-ignore
                var barWidth = chart.getDatasetMeta(i).data[index].width;
                if(text.length<4)
                    xPosition-=25;
                else if(text.length<6)
                    xPosition-=30;
                else if(text.length<8)
                    xPosition-=35;
                else
                    xPosition-=40;
                var yPosition=chart.getDatasetMeta(i).data[index].y;
                // if(yPosition<=150) {
                    // yPosition -= 10;
                    // yPosition += 25;
                    // ctx.fillStyle="#ffffff";
                    // ctx.fillRect(chart.getDatasetMeta(i).data[index].x-1-(barWidth/2),yPosition,barWidth+2,40)
                // }
                ctx.fillStyle="#666666";
                // if(Number(dataPoint)!=0){
                //     ctx.fillText(`${text}`,
                //         xPosition,yPosition)
                // }
            })
        }

    }
}

export const innerBarText={
    id:'innerBarText',
    afterDatasetsDraw(chart: Chart, args: EmptyObject, options: any) {
        const{ ctx,data,chartArea:{right},scales:{x,y}}=chart;
        ctx.save();
        data.datasets[0].data.forEach((dataPoint,index)=>{
            ctx.font='bold 15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
            ctx.fillStyle="#666666";
            var xPosition=chart.getDatasetMeta(0).data[index].x;
            if(right-xPosition<450) {
                xPosition-=data.labels==null?0:(data.labels[index]+"").length*9+20;
                ctx.fillStyle="#ffffff";
            }else
                xPosition+=5;
            ctx.fillText(`${data.labels==null?"":data.labels[index] }`,xPosition,y.getPixelForValue(index)+4)
        })
    }
}

export const CenterBarText={
    id:'CenterBarText',
    beforeDatasetsDraw(chart: Chart ) {
        const{ ctx,data,chartArea:{right},scales:{x,y}}=chart;
        ctx.save();
        // Texto total adentro del grafico
        // if(data.datasets.length > 0){
        //     let total = 0;
        //     data.datasets[0].data.forEach((a) =>{ a && (total += Number(a))});

        //     const xCoor = chart.getDatasetMeta(0).data[0]?.x;
        //     const yCoor = chart.getDatasetMeta(0).data[0]?.y;
        //     ctx.font = 'bold 14px sans-serif';
        //     ctx.fillStyle= "black";
        //     ctx.textAlign= "center";
        //     ctx.fillText(total?.toString(), xCoor, yCoor);
        // }
    }
}