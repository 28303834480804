import { Component } from 'react';
import NavigationTabLinks from '../containers/navigationTabLinks';
import {WebCache} from "../../services/WebCache";

interface IProps { }

class NavigationTabLinksForReports extends Component {

    items:any;

 constructor(props: IProps) {
        super(props);

     this.items=[];
     this.items.push({ to: '/reports/settlements', icon: 'fe fe-credit-card', label: 'settlements' });
     if(WebCache.GetConciliationData().conciliationEnabled)
        this.items.push({ to: '/reports/presentations', icon: 'fe fe-calendar', label: 'presentations' });
    if(WebCache.getCurrentUser()?.countryId==2)
            this.items.push({ to: '/reports/operationTable', icon: 'fe fe-grid', label: 'operationTable' });
     this.items.push({ to: '/reports/presentationDownloads', icon: 'fe fe-download', label: 'presentationDownloads' });

    }




    render() {
        return (
            <NavigationTabLinks
                items={this.items}
            ></NavigationTabLinks>
        )
    };
}

export default NavigationTabLinksForReports;
