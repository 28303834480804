import { AxiosResponse } from 'axios';
import React, { ChangeEvent, RefObject } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker";
import { ToastContainer, toast } from "react-toastify";
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import { DateHelper } from '../../helpers/DateHelper';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { CurrencyOption } from "../../models/Currency";
import {
    DateRangeType,
    ISettlement,
    ISettlementRequest,
    ISettlementResponse, ISettlementTaxTotalDto
} from "../../models/ISettlementData";
import { IShortcutCaptureResult } from "../../models/IShortcut";
import { IUserProfile } from '../../models/IUserProfile';
import { Option } from "../../models/Option";
import { SettlementService } from "../../services/SettlementService";
import { UtilService } from "../../services/UtilService";
import { WebCache } from '../../services/WebCache';
import CustomReCAPTCHA from '../CustomCaptcha';
import ErrorsView from '../ErrorsView';
import Pagination from '../Pagination';
import FormFilter from '../containers/form-filter';
import Page from '../containers/page';
import Button from '../controls/buttons/button';
import DateField from '../controls/fields/dateField';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import PaymentTaxGrid from "./PaymentTaxGrid";
import "./Payments.scss";
import "./SettlementReport.css";
import SettlementTotal from "./SettlementTotal";
import Swal from "sweetalert2";
interface IProps {
    t: TFunction
}
interface IState {
    msg: string,
    dateRange: DateObject[],
    activePage: number,
    mainPage: number,
    pages: number[],
    settlements: ISettlement[],
    columns: Array<string>
    errors: Array<string>,
    columnsToShow: Array<string>,
    rowDetail: { title: string, total: string, data: Array<{ prop: string, value: string }> } | null,
    transactionDetailSettlement?: ISettlement,
    settlementNumberFilter: string,
    currencyFilter: string,
    dateRangeType: DateRangeType,
    currencyOptions: CurrencyOption[],
    accordionRef: RefObject<any>,
    taxTotals: ISettlementTaxTotalDto,
    bankOptions: Option[],
    fiscalDocuments:Option[],
    merchantNumbers:Option[],
    bankFilter: string,
    fiscalDocument: string;
    merchantDocument: string;
    isCaptchaVerified: boolean,
    currentUser : IUserProfile | null
}
const T_PREFIX: string = 'pages.paymentTaxReport.';
const pageSize=13;
const pageQuantity=3
let doQuery=false;
const defaultPages = [1, 2, 3]
let lastFiscalDocument:string=""
class PaymentTaxReport extends React.Component<IProps, IState>{
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private t: TFunction;

    onDateRangeTypeChange = (event: DateRangeType) => {
        let columnsToShow: string[] = this.state.columnsToShow;
        
        if (event === "PRESENTATION_DATE") {
            columnsToShow[0] = "PresentationDate".toUpperCase();
        } else if (event === "PAYMENT_DATE") {
            columnsToShow[0] = "VtoDate".toUpperCase();
        }
        this.setState({...this.state, columnsToShow: columnsToShow});
    }

    componentDidMount() {
        if (doQuery) {
            doQuery = false;
            this.getSettlements();
        }
    }
    lastFiscalDocument="";
    constructor(props: IProps) {
        super(props);

        this.t = props.t;
        let shortcut = null;
        if (window.location.href.indexOf("shortcuts=") > 0) {
            shortcut = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("shortcuts="))).get("shortcuts");
        }

        let settlementNumberFilter = "";
        let dateRangeType: DateRangeType = "PAYMENT_DATE";
        const currencyOptions = UtilService.getCurrencyOptions().filter((x, index) => index !== 0);
        let currencyFilter = currencyOptions[0].value;
        const allBanks = WebCache.getAllPayerBanks(true);
        let [ fromDate, toDate ] = DateHelper.buildDateRange();
        if (shortcut != null && shortcut.length > 0) {
            doQuery = true;
            let shortcutDto: IShortcutCaptureResult[] = JSON.parse(shortcut);
            for (var i = 0; i < shortcutDto.length; i++) {
                switch (shortcutDto[i].type) {
                    case "CURRENCY":
                        currencyFilter = currencyOptions.filter( currency => currency.value === (shortcutDto[i].data + "") )[0].value;
                        break;
                    case "SETTLEMENT_NUMBER":
                        settlementNumberFilter = shortcutDto[i].data + "";
                        break;
                    case "PAYMENT_DATE":
                        dateRangeType="PAYMENT_DATE";
                        fromDate = new DateObject(shortcutDto[i].data);// [new DateObject(shortcutDto[i].data),new DateObject(shortcutDto[i].data)];
                        toDate = new DateObject(shortcutDto[i].data);
                        break;
                }
            }
        }

        const dateRange = [ fromDate, toDate ];
        this.state = {
            msg: "",
            dateRange,
            activePage: 1,
            mainPage: 0,
            pages: defaultPages,
            columns: [],
            settlements: [],
            errors: [],
            columnsToShow: ["PRESENTATIONDATE"],
            rowDetail: null,
            settlementNumberFilter,
            currencyFilter,
            currencyOptions,
            dateRangeType,
            taxTotals: { tariffTaxAmount: 0, taxPerceptionsAmount: 0, taxWithholdingsAmount: 0 },
            accordionRef: React.createRef(),
            bankOptions: allBanks,
            bankFilter: allBanks[0].value,
            isCaptchaVerified: false,
            fiscalDocument: WebCache.getAllMerchantDocuments(true,true)[0]?.value+"",
            merchantDocument: "",
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions( WebCache.getAllMerchantDocuments(false,true)[0]?.label,true,true),
            fiscalDocuments:WebCache.getAllMerchantDocuments(true,true),
            currentUser: WebCache.getCurrentUser()
        }
        
        for(let pageQuantityIndex=1; pageQuantityIndex <= pageQuantity; pageQuantityIndex++) {
            this.state.pages.push(pageQuantityIndex);
        }
    }
    clearFilters = () => {
        this.setState({
            ...this.state,
            dateRange: DateHelper.buildDateRange(),
            settlementNumberFilter: "",
            currencyFilter: "",
            bankFilter: "",
            fiscalDocument: WebCache.getAllMerchantDocuments(true,true)[0].value+"",
            merchantNumbers:OptionMapperHelper.getAllMerchantNumbersOptions( WebCache.getAllMerchantDocuments(false,true)[0].label,true,true)
        });                       
    }

    getSettlementsRequest = (all:boolean) => {
        const tranWebModel: ISettlementRequest = {
            From: DateHelper.dateObjectToString(this.state.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.dateRange[1]),
            DateRangeType: this.state.dateRangeType,
            SettlementNumber: (this.state.settlementNumberFilter === null || this.state.settlementNumberFilter === "" ) ? "" : this.state.settlementNumberFilter, //Number(this.state.settlementNumberFilter),
            Currency: (this.state.currencyFilter === null||this.state.currencyFilter === "")?"":this.state.currencyFilter,
            Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
            Take: all ? 0 : ((pageSize * pageQuantity) + 1),
            PayerEntity: this.state.bankFilter.replace("payingEntity-",""),
            MerchantDocument: this.state.fiscalDocument,
            MerchantNumber: this.state.merchantDocument
        };
        return tranWebModel;
    }

    getFromBuscar = () => {
        this.setState({
            ...this.state, activePage: 1, pages: defaultPages,
            mainPage: 0
        }, ()=>{
            this.getSettlements();
        });
    }

    getSettlements = () => {
        SettlementService.getSettlementTaxes(this.getSettlementsRequest(false)).then((response: AxiosResponse<ISettlementResponse>) => {
            if (response === null ||
                response.data === null ||
                response.data.settlements === null ||
                response.data.settlements.length === 0
            ) {
                this.setState({ ...this.state, settlements: [], errors: [] });
                return;
            }
            let newColumns = [];
            for (let prop in  response.data.settlements[0]) {
                if(prop.toUpperCase() === "TOTALDETAIL") {
                    continue;
                } else if (prop.toUpperCase() === "TAXDETAIL") {
                    continue;
                } else if (prop.toUpperCase() === "VALDETAIL") {
                    continue;
                }
                newColumns.push(prop)
            }
            let columnsToShow = [ this.state.columnsToShow[0], "PAYINGENTITYNAME", "MERCHANTNUMBER", "SETTLEMENTNUMBER" ];
            newColumns.push("payingEntityName");
            
            let taxColumns = {
                taxDetail: {taxLaw17934:false,taxLaw18999:false,taxLaw19210:false,tariffTaxAmount:false,
                    creditDebitTaxAmount:false,taxTotalFinancialDiscount:false,taxServiceAmount:false,stampAmount:false,taxWithoutInterestAmount:false,taxWithholdingsAmount:false,
                    profitsWithholdingsAmount:false,ibbWithholdingsAmount:false,afamWithholdingsAmount:false,ibbCordobaAmount:false,ibbAmountTucuman:false,
                    ibbWithholdingsSirtacAmount:false,taxPerceptionsAmount:false,ibbPerceptionsAmount:false,},
                valDetail:{taxLaw25063:false,netSaleFinancialDiscount:false,taxFinancialDiscount:false,valStampAmount:false,taxPerception:false,ibbPerception:false},
            }

            for (let index in response.data.settlements) {
                let sett = response.data.settlements[index];
                for (let parent in taxColumns) {
                    // @ts-ignore
                    for (let prop in taxColumns[parent]) {
                        // @ts-ignore
                        if(sett[parent] !== null && sett[parent][prop] !== null && sett[parent][prop] !== 0)
                            // @ts-ignore
                            taxColumns[parent][prop]=true;
                    }
                }
            }

            for (let parent in taxColumns) {
                // @ts-ignore
                for (let prop in taxColumns[parent]) {
                    if (prop.toUpperCase() === "TAXLAW19210" && this.state.currentUser?.countryId === 1) {
                        continue;
                    }
                    // @ts-ignore
                    if(taxColumns[parent][prop]) {
                        newColumns.push(parent + "." + prop);
                        columnsToShow.push((parent + "." + prop));
                    }
                }
            }            

            const allBanks = WebCache.getAllPayerBanks(true);
            response.data.settlements = response.data.settlements.map((settlementItem) => {
                let bank = null;
                if (allBanks) {
                    bank = allBanks.find((bank) => {
                        return bank.value.endsWith(settlementItem.payingEntity);
                    });
                }
                settlementItem.payingEntityName = (bank) ? bank.label : String(settlementItem.payingEntity);

                return settlementItem;
            })

            this.setState({
                ...this.state,
                columns: newColumns,
                settlements: response.data.settlements,
                columnsToShow,
                taxTotals: response.data.taxTotals,
                errors: []
            });
        }).catch(error => {
            console.log('error:', error);
        });
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getSettlementsWithCouponsToExcel = () => {
        if (this.state.isCaptchaVerified) {
            SettlementService.getAllWithCouponsToExcel(this.getSettlementsRequest(true)).then((response: AxiosResponse<Blob>) => {
            }).catch(error => {
                this.setState({...this.state,errors: error.response.data});
                console.log('error:', error);
            })
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }

    getSettlementsToExcel = () => {
        if (this.state.isCaptchaVerified) {
            SettlementService.generateTaxSettlementExcel(this.getSettlementsRequest(true)).then((response: AxiosResponse<Blob>) => {
            }).catch(error => {
                this.setState({...this.state,errors: error.response.data});
                console.log('error:', error);
            });
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }

    processAction = (columnName:string,object:ISettlement) => {
        let currencySymbol = CurrencyHelper.getCurrencySymbol(object.currency);

        if (columnName.toUpperCase() === "NETTOTAL") {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.totalDetail,"totalDetail",currencySymbol,false)});
        } else if (columnName.toUpperCase() === "TaxWithholdings".toUpperCase()) {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.taxDetail,"taxDetail",currencySymbol,true)});
        } else if (columnName.toUpperCase() === "OriginalSettlementNetAmount".toUpperCase()) {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.totalDetail,"totalDetail",currencySymbol,false)});
        } else if (columnName.toUpperCase() === "VALCHARGES") {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.valDetail,"valDetail",currencySymbol,false)});
        } else if (columnName.toUpperCase() === "TariffAndFinancialCosts".toUpperCase()) {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.totalDetail,"totalDetail",currencySymbol,false)});
        } else if (columnName.toUpperCase() === "CONTRAPARTSAMOUNT") {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.totalDetail.contraparts,"contrapartsAmount",currencySymbol,false)});
        } else if (columnName.toUpperCase() === "CHARGESANDPAYMENTSAMOUNT".toUpperCase()) {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.totalDetail.chargesAndPayments,"chargesAndPaymentsAmount",currencySymbol,false)});
        } else if (columnName.toUpperCase() === "TRANSACTIONCOUNT") {
            this.setState({...this.state,transactionDetailSettlement:object})
            setTimeout(this.scrollToTransactions,1000);
        } else if (columnName.toUpperCase() === "NetSaleFinancialDiscount".toUpperCase()) {
            this.setState({...this.state,rowDetail:SettlementService.buildTotalDetail(object.valDetail,"valDetail",currencySymbol,false)});
        }
    }
    // @ts-ignore
    private paymentTransactionReportDiv: HTMLDivElement | null;

    scrollToTransactions = () => {
        this.paymentTransactionReportDiv?.scrollIntoView({ behavior: "smooth" });
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if(lastFiscalDocument!=this.state.fiscalDocument){
            lastFiscalDocument=this.state.fiscalDocument;
            let merchantNumbers= OptionMapperHelper.getAllMerchantNumbersOptions(lastFiscalDocument,true,true)
            this.setState({...this.state,merchantDocument:merchantNumbers[0].value,merchantNumbers:merchantNumbers})
        }
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let clientDocument;
        if (currentUser !== undefined && currentUser === 2) {
            clientDocument = t("merchantDocument") ;
        } else {
            clientDocument = t("merchantDocumentArg");
        }
        return (
                <Page>
                <>
                <ToastContainer
                    autoClose={3000}
                ></ToastContainer>
                <SettlementTotal rowDetail={this.state.rowDetail} link={null}></SettlementTotal>
                <div className="main-container container-fluid">
                    <div className='row'>
                        <div className='col-md-12'>
                            <ErrorsView errors={this.state.errors}/>
                        </div>
                    </div>
                    <div className="row pe-5">
                        <div className="col-md-12 border-bottom">
                            <h2 className="title">{t("taxes")}</h2>
                        </div>
                        <div className="filter-accordion mt-3">
                            <div className="row">
                                <div className="d-flex justify-content-start pt-2" >
                                    <RadioButtonGroup
                                        model={[ this.state, this.setState.bind(this) ]} 
                                        attr="dateRangeType" 
                                        className='radioStyle'
                                        fieldDefinitions={[
                                            { key: 'originalPresentationDate', value: 'PAYMENT_DATE', label: 'settlement-vtoDate' },
                                            { key: 'aggregationPresentationDate', value: 'PRESENTATION_DATE', label: 'settlement-presentationDate' }
                                        ]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => this.onDateRangeTypeChange(event.target.value as DateRangeType) }
                                    ></RadioButtonGroup>
                                </div>
                            </div>
                            <div className="row mt-4" >
                                <div className="col-md-3 div-info-width-4" style={{textAlign:"start"}}>
                                    <div className='div-info'>
                                        <label className="form-label"> {t('taxAmount')}</label>
                                        <label className="border-start-0 ms-0 amount-label" >{
                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol(this.state.currencyFilter) }).format(this.state.taxTotals.tariffTaxAmount)
                                        }</label>
                                    </div>
                                </div>
                                <div className="col-md-3 div-info-width-4" style={{textAlign:"start"}}>
                                    <div className='div-info'>
                                        <label className="form-label"> {t('taxWithholds')}</label>
                                        <label className="border-start-0 ms-0 amount-label" >{
                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol(this.state.currencyFilter) }).format(this.state.taxTotals.taxWithholdingsAmount)
                                        }</label>
                                    </div>
                                </div>
                                <div className="col-md-3 div-info-width-4" style={{textAlign:"start"}}>
                                    <div className='div-info'>
                                        <label className="form-label"> {t('taxPerceptions')}</label>
                                        <label className="border-start-0 ms-0 amount-label" >{
                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol(this.state.currencyFilter) }).format(this.state.taxTotals.taxPerceptionsAmount)
                                        }</label>
                                    </div>
                                </div>
                                <div className="col-md-3 div-info-width-4" style={{textAlign:"start"}}>
                                    <div className='div-info'>
                                        <label className="form-label"> {t('taxTotals')}</label>
                                        <label className="border-start-0 ms-0 amount-label" >{
                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol(this.state.currencyFilter) }).format(this.state.taxTotals.tariffTaxAmount+this.state.taxTotals.taxWithholdingsAmount+this.state.taxTotals.taxPerceptionsAmount)
                                        }</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-3'>
                                    <FormFilter
                                        model={[ this.state, this.setState.bind(this) ]}
                                        hidden={true}
                                        clear={this.clearFilters}
                                        onSubmit={() => {
                                            this.getFromBuscar(); 
                                        }} 
                                    >
                                        <div className="row form-filter mt-2">
                                            <div className="col-md-10" >
                                                <DateField attr="dateRange" label="dateRange" range ></DateField>
                                            </div>
                                            <div className="col-md-10">
                                                <TextField attr="settlementNumberFilter" label="settlementNumber"></TextField>
                                            </div>
                                            <div className="col-md-10">
                                                <SelectField attr="currencyFilter" label="currency" options={this.state.currencyOptions} ></SelectField>
                                            </div>
                                            <div className="col-md-10">
                                                <SelectField attr='bankFilter' label='bank' options={this.optionMapper.translateOptionValues(this.state.bankOptions)} ></SelectField>
                                            </div>
                                            <div className="col-md-10">
                                                <SelectField attr='fiscalDocument' label={clientDocument} options={this.optionMapper.translateOptionValues(this.state.fiscalDocuments)} ></SelectField>
                                            </div>
                                            <div className="col-md-10">
                                                <SelectField attr='merchantDocument' label='merchantNumbers' options={this.optionMapper.translateOptionValues(this.state.merchantNumbers)} ></SelectField>
                                            </div>
                                        </div>
                                    </FormFilter>
                                </div>
                            </div>
                            <PaymentTaxGrid
                                settlements={this.state.settlements}
                                columns={this.state.columns}
                                columnsToShow={this.state.columnsToShow}
                                mainPage={this.state.mainPage}
                                pages={this.state.pages}
                                activePage={this.state.activePage}
                                processAction={this.processAction}
                            ></PaymentTaxGrid>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <Pagination
                                        activePageProp={this.state.activePage}
                                        pageSize={pageSize}
                                        pageQuantity={pageQuantity}
                                        modifyMainPage={(value: number, newPages: Array<number>) => {
                                            this.setState({
                                                mainPage: this.state.mainPage + value, pages: newPages
                                            }, () => this.getSettlements())
                                        }}
                                        changePage={(page: number) => {
                                            this.setState({ activePage: page }, () => { });
                                        }}
                                        dataL={this.state.settlements.length}
                                        mainPage={this.state.mainPage}
                                    ></Pagination>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'></div>
                                <div className="col-md-3">
                                    <CustomReCAPTCHA onVerify={this.handleCaptchaVerification} /> 
                                </div>
                                <div className="col-md-3">
                                    <Button disabled={this.state.settlements.length === 0} onClick={this.getSettlementsToExcel}>
                                        {t("download-excel")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Page>
        )
    };
}
export default withTranslation() (PaymentTaxReport);
