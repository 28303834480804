import {axiosApiInstance} from "./HttpService";
import { ITransactionWebModelRequest} from "../requests/ITransactionWebModelRequest";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import { ISenderProfileWebModelRequest } from "../requests/ISenderProfileWebModelRequest";
import Swal from "sweetalert2";
import i18next from "i18next";
import jsPDF from "jspdf";

export class TransactionService{

    public static async getAll(request: ITransactionWebModelRequest){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/Transactions`;
        return await axiosApiInstance.post(dataURL, request);
    }

    public static async getCurrentTransactions(request: ITransactionWebModelRequest){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/CurrentTransactions`;
        return await axiosApiInstance.post(dataURL, request);
    }

    public static async getQrTransactionsAll(request: ITransactionWebModelRequest){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/qrTransactions`;
        return await axiosApiInstance.post(dataURL, request);
    }

    // public static async getSenderProfile(request: ISenderProfileWebModelRequest){
    //     let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/Transactions`;
    //     return await axiosApiInstance.post(dataURL, request);
    // }

    public static getFeatures() {
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Features/getFeatures`;
        return axiosApiInstance.post(dataURL, {})
    }

    public static async getAllIssuers(){
        let dataURL: string = `${process.env.REACT_APP_API_URL}/api/Transaction/GetAllIssuers`;
        return await axiosApiInstance.get(dataURL);
    }

    public static async getAllToExcel(request: ITransactionWebModelRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateExcel`;
        let options: AxiosRequestConfig = {                         
            responseType: 'blob' // don't forget this
          };

        return await axiosApiInstance.post(dataURL, request, options);
    }

    public static async getTicket(transactionID: string, transactionType: string){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/Ticket` + transactionID + '/' + transactionType;
        return await axiosApiInstance.get(dataURL);
    }

    public static async getLinkAutomaticDebit(){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/GetUrlAutomaticDebit`;
        return await axiosApiInstance.get(dataURL);
    }

    public static async getTotalsByIssuerAndCurrency(transactionID: string){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/TotalsByIssuerAndCurrency` + transactionID;
        return await axiosApiInstance.get(dataURL);
    }

    static getTransactionStatusByTerminal(terminals: string[]) {
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/Transaction/getTransactionStatusByTerminal`;
        return axiosApiInstance.post(dataURL,{terminals:terminals});
    }

    public static showTicket(tranId: string, transactionType: string){
            TransactionService.getTicket(tranId, transactionType).then((response: AxiosResponse<string>) => {
                //Swal.fire(tranId, response.data);
                Swal.fire({
                    title: 'Transacción Nro. ' + tranId,
                    html: response.data,
                    denyButtonColor: 'gray',
                    denyButtonText:i18next.t("close")+"",
                    showDenyButton:true,
                    confirmButtonColor:'#f60',
                    confirmButtonText:i18next.t("PDF")+""
                }).then((result:any) => {
                    if (result.isConfirmed) {
                        let div:HTMLElement|null=null;
                        // @ts-ignore
                        div=document.getElementById("swal2-html-container");
                        if(div!=null){
                            div.style.fontFamily = "monospace";
                            let contain: HTMLElement|null = null;
                            // @ts-ignore
                            contain = div.getElementsByClassName("contain")[0];
                            if(contain != null){
                                // @ts-ignore
                                contain.style.width = "400px";
                                contain.style.margin = "0px";
                                contain.style.fontSize = "6px";
                            }
                            
                            const doc = new jsPDF({
                                orientation: 'portrait',
                                unit: 'px',
                                format: "a4",
                            });
                            /*
                            As jspdf documentation:
                            Autopaging is used to set the auto paging mode.
                            false: Auto paging is disabled.
                            true or 'slice': Will cut shapes or text chunks across page breaks. Will possibly slice text in half, making it difficult to read.
                            'text': Trys not to cut text in half across page breaks. Works best for documents consisting mostly of a single column of text.
                            Default is true.
                            */
                           if(transactionType == "L") {
                                doc.html(div, {
                                    margin: 0,
                                    autoPaging: 'text',
                                    async callback(doc) {
                                        await doc.save('Voucher-'+tranId+'.pdf');
                                    },
                                });
                           } else {
                                doc.html(div, {
                                    windowWidth: -50,
                                    width: 50,
                                    autoPaging: 'text',
                                    async callback(doc) {
                                        await doc.save('Voucher-'+tranId+'.pdf');
                                    },
                                    html2canvas: {
                                        scale: 0.9
                                    }
                                });
                           }
                            
                        }
                        return true;
                    } else if (result.isDenied) {

                    }
                })
            }).catch(error => console.log('error:', error));
    }

    static buildTotalDetail(object:any,title:string,currencySymbol:string,accumulateTotal:boolean):{title:string,total:string,data:{ prop:string,value:string }[]} {
        var rowDetail:{ prop:string,value:string }[]=[];
        var total=0;
        for(var i in object){
            rowDetail.push({prop:i,value:Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(Number(object[i]+""))})
            total+=Number(object[i]+"");
        }
        return {title:title,data:rowDetail,total:accumulateTotal?Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(total):""};
    }

    static generateQR(fiscalDocument:string){
        let dataURL:string = `${process.env.REACT_APP_API_URL}/api/QrViewer/getQr`;
        return axiosApiInstance.post(dataURL, {
                fiscalDocument:fiscalDocument
        }); 
    }
}
