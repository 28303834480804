import { axiosApiInstance } from "./HttpService";
import {
    IFileSettlement,
    IOperationTableRequest,
    IPresentationRequest,
    ISettlementOnboardingRequest,
    ISettlementRequest,
    ISettlementTransactionRequest
} from "../models/ISettlementData";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import { DownloadFileHelper } from "../helpers/DownloadFileHelper";
import { WebCache } from "./WebCache";
import {IDailySettlementsRequest, IMonthlySettlementsRequest} from "../requests/ISettlementsRequest";
import Swal from "sweetalert2";
import i18next from "i18next";

export class SettlementService {    

    /*
    public static async addOrDisabledOnboardingSettlement(request: ISettlementOnboardingRequest){
        let isSettlementOnboardingEnabled = WebCache.isSettlementOnboardingEnabled();
        let dataURL: string = "";
        
        if(isSettlementOnboardingEnabled){
            dataURL = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementOnboarding/disableSettlementOnboarding`;
            return await axiosApiInstance.post(dataURL, { FiscalDocument: request.FiscalDocument});
        }else{
            dataURL = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementOnboarding/addOnboardingSettlement`;
            return await axiosApiInstance.post(dataURL, request);
        }
    }   */

    public static async getSettlementEnabled(/*request: ISettlementTransactionRequest*/){
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementOnboarding/getSettlementEnabled`;
        return await axiosApiInstance.post(dataURL, /*request*/);
    }

    public static async getConciliationTotals(request: ISettlementTransactionRequest){
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/getConciliationTotals`;
        return await axiosApiInstance.post(dataURL, request);
    }
    public static async getSettlementTransactions(request: ISettlementTransactionRequest){
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/SettlementTransactions`;
        return await axiosApiInstance.post(dataURL, request);
    }

    public static async getSettlements(request: ISettlementRequest){
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/SettlementListPayMentReport`;
        return await axiosApiInstance.post(dataURL, request);
    }

    public static async getSettlementTaxes(request: ISettlementRequest){
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/getSettlementTaxes`;
        return await axiosApiInstance.post(dataURL, request);
    }
    public static async getSettlementCalendar(request: { From: string, To: string,Currency:string,MerchantNumber:string,MerchantDocument:string,PayerEntity:string,ProductCode:string,PayingEntityBranch:string}){
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/getSettlementCalendar`;
        return await axiosApiInstance.post(dataURL, request);
    }

    public static async getAllWithCouponsToExcel(request: ISettlementRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateSettlementWithCouponsExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }
    public static async getAllToExcel(request: ISettlementRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateSettlementExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }

    public static async getAllPresentationsToExcel(request: IPresentationRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generatePresentationExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }

    public static async getOperationTableToExcel(request: IOperationTableRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateOperationTableExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }

    public static async generateTaxSettlementExcel(request: ISettlementRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateTaxSettlementExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };
        return await axiosApiInstance.post(dataURL, request, options);
    }
    public static async getAllTransactionsToExcel(request: ISettlementTransactionRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateTransactionsExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }
    public static async generateDailySettlementExcel(request: IDailySettlementsRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateDailySettlementExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }

    public static async generateMonthlySettlementExcel(request: IMonthlySettlementsRequest){
        Swal.fire({
            html:i18next.t("settlementsDownloadFileNotification"),
            confirmButtonColor:'#f60'
        });
        let dataURL:string = `${process.env.REACT_APP_API_TRXSENDER_URL}/apiTrxSender/FileExporter/generateMonthlySettlementExcel`;
        let options: AxiosRequestConfig = {
            responseType: 'blob' // don't forget this
        };

        return await axiosApiInstance.post(dataURL, request, options);
    }


    static uploadSettlement(file: File) {
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/SettlementTask/UploadSettlements`;
        const formData = new FormData();
        formData.append(`formFile`, file);
        formData.append(`fileName`, file.name);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axiosApiInstance.post(dataURL, formData, config);
    }

    static buildTotalDetail(object:any,title:string,currencySymbol:string,accumulateTotal:boolean):{title:string,total:string,data:{ prop:string,value:string }[]} {
        var rowDetail:{ prop:string,value:string }[]=[];
        var total=0;
        for(var i in object){
            if(i=="contraparts"||i=="chargesAndPayments"){
                for(var j in object[i]){
                    rowDetail.push({prop:j,value:Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(Number(object[i][j]+""))})
                    total+=Number(object[i][j]+"");
                }
            }else{
                rowDetail.push({prop:i,value:Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(Number(object[i]+""))})
                total+=Number(object[i]+"");
            }
        }
        return {title:title,data:rowDetail,total:accumulateTotal?Intl.NumberFormat('es-UY', { style: 'currency', currency: currencySymbol }).format(total):""};
    }

    static async GetConciliationStatus(request: { Skip:number,Take:number,AcquirerId: string; From: string | undefined; To: string | undefined }) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/GetConciliationStatus`;
        return await axiosApiInstance.post(dataURL, request);
    }


    static mergeTrx(request: { paymentId: any; transactionId: string }) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/mergeTrx`;
        return axiosApiInstance.post(dataURL, request);
    }

    static riseSupportTicket(conciliationTransactions: any) {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/riseSupportTicket`;
        return axiosApiInstance.post(dataURL, {transactions:conciliationTransactions});
    }

    static async getAllPayerBanksAndProductsFromServer(): Promise<AxiosResponse<{merchantDocuments:{id:string,value:string }[],wholesalerMerchants:{id:string,value:string }[],merchantNumbers:{id:string,value:string }[],banks:{id:string,value:string }[],products:{id:string,value:string }[],bankBranches:{id:string,value:string }[]}>> {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/getAllPayerBanksAndProducts`;
        return await axiosApiInstance.post(dataURL, {});
    }

    static downloadUploadedFileRequest(fileName: string): Promise<AxiosResponse<any>> {
        let dataURL: string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/downloadUploadedFile`;
        return axiosApiInstance.post(dataURL, {fileName:fileName});
    }

    static downloadUploadedFile(fileName: string): void {
        SettlementService.downloadUploadedFileRequest(fileName).then((response: AxiosResponse<any>) => {
            // @ts-ignore
            let outputFilename = response.headers['content-disposition']
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .trim();
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            link.click();
        }).catch(error => {
            console.log('error:', error);
        });
    }

    static getOperationTable(request: IOperationTableRequest) {
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/OperationTable`;
        return axiosApiInstance.post(dataURL, request);
    }

    static getPresentationTotals(request: IPresentationRequest) {
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Presentation/PresentationList`;
        return axiosApiInstance.post(dataURL, request);
    }
    
    static getSettlementFileList(request: ISettlementRequest): Promise<AxiosResponse<Array<IFileSettlement>>> {
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Settlement/SettlementFileList`;
        return axiosApiInstance.post(dataURL, request);
    }
   
    static getPresentationFileList(request: ISettlementRequest): Promise<AxiosResponse<Array<IFileSettlement>>> {
        let dataURL:string = `${process.env.REACT_APP_API_SETTLEMENT_URL}/settlement/Presentation/PresentationFileList`;
        return axiosApiInstance.post(dataURL, request);
    }
}
