import React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { WebCache } from "../services/WebCache";
import { IAllTerminalResponse, IBranche, ILocation, IPayerTaxId } from "../models/ITerminal";
import SelectField from './controls/fields/selectField';
import { OptionMapperHelper } from '../helpers/OptionMapperHelper';
import { Option } from 'react-multi-select-component';
import { TerminalsService } from '../services/TerminalsService';
import { AxiosResponse } from 'axios';
import { Console } from 'console';

interface IProps {
    changeFilter: (taxPayerFilter: string, branchFilter: string, departmentFilter: string, locationFilter: string, departmentName: string, locationName: string, branchName: string, clientDocument: string) => void,
    t: TFunction,
    filter: {
        taxPayerFilter: string,
        branchFilter: string,
        departmentFilter: string,
        locationFilter: string
    }
}

interface IState {
    departmentFilter: string
    locationFilter: string
    currentLocations: ILocation[],
    branchFilter: string
    currentBranches: IBranche[],
    currentPayerIds: Option[],
    departmentName: string,
    locationName: string,
    branchName: string,
    taxPayerId: string
    taxPayerName: string,
    taxPayerFilter: string
}

const allRecordsFilter = "0000";
class TerminalLocation extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            departmentFilter: "",
            locationFilter: "",
            currentLocations: WebCache.getAllLocations(),
            branchFilter: "",
            currentBranches: WebCache.getAllBranches(),
            currentPayerIds: WebCache.getAllMerchantDocuments(true, true),
            departmentName: "",
            locationName: "",
            branchName: "",
            taxPayerId: "",
            taxPayerName: "",
            taxPayerFilter: ""
        }
    }

    componentDidMount(): void {
        const translatedPayerIds = WebCache.getAllMerchantDocuments(true, true)
            .map(doc => { return { ...doc, label: this.props.t(doc.label) }; });
        this.setState({ ...this.state, currentPayerIds: translatedPayerIds });
    }

    componentDidUpdate(): void {
        if (
            this.props.filter.branchFilter === " " && this.state.branchFilter != " " &&
            this.props.filter.locationFilter === " " && this.state.locationFilter != " " &&
            this.props.filter.departmentFilter === " " && this.state.departmentFilter != " " &&
            this.props.filter.taxPayerFilter === " " && this.state.taxPayerFilter != " "
        ) {
            this.setState({
                ...this.state,
                taxPayerFilter: " ",
                locationFilter: " ",
                departmentFilter: " ",
                branchFilter: " "
            })
        }
    }

    handleChange(event: any) {
        const targetId = event.target.id;
        const targetValue = event.target.value;
        let taxPayerIds = WebCache.getAllMerchantDocuments(true, true);
        let taxPayerIdName = "";
        let branchName = "";
        let locationName = "";
        let departmentName = "";
        let branches = WebCache.getAllBranches();

        switch (true) {
            case targetId.includes("taxPayerFilter"):
                taxPayerIdName = targetValue === '' ? this.state.currentPayerIds[0].label : targetValue;
                this.setState({ ...this.state, taxPayerFilter: targetValue, taxPayerName: taxPayerIdName });
                this.props.changeFilter(targetValue, this.state.branchFilter, this.state.departmentFilter, this.state.locationFilter, this.state.departmentName, this.state.locationName, this.state.branchName, taxPayerIdName);
                break;
    
            case targetId.includes("branchFilter"):
                const branch = WebCache.getAllBranches().find(dep => dep.id == targetValue);
                branchName = branch ? branch.name : ""               
                if (targetId != (`input-${allRecordsFilter}`)) {
                    const filteredPayerIds = taxPayerIds.filter(payerId =>
                        WebCache.getAllTerminals().some(t => t.clientDocument === payerId.value && t.branchName === branchName)
                    ).map(payerId => ({
                        value: payerId.value,
                        label: payerId.value,
                        key: payerId.key,
                        disabled: payerId.disabled
                    }));
                    //This correctly filters the client documents by branch name, but the "changeFilter" function doesn't update the information.
                    this.setState({ ...this.state, branchFilter: targetValue, branchName, currentPayerIds: filteredPayerIds, taxPayerName: "", taxPayerId: allRecordsFilter });
                } else {
                    this.setState({ ...this.state, branchFilter: targetValue, branchName, currentPayerIds: taxPayerIds, taxPayerName: "", taxPayerId: allRecordsFilter });
                }
                this.props.changeFilter(allRecordsFilter, targetValue, this.state.departmentFilter, this.state.locationFilter, this.state.departmentName, this.state.locationName, branchName, "");
                break;
    
            case targetId.includes("locationFilter"):
                const location = WebCache.getAllLocations().find(dep => dep.id == targetValue);
                if (targetValue !== allRecordsFilter) {
                    locationName = location ? location.name : "";
                    const filteredPayerIds = taxPayerIds.filter(payerId =>
                        WebCache.getAllTerminals().some(t => t.clientDocument === payerId.value && t.locationName === locationName)
                    ).map(payerId => ({
                        value: payerId.value,
                        label: payerId.value,
                        key: payerId.key,
                        disabled: payerId.disabled
                    }));
                    const matchingBranch = branches.find(branch => branch.locationId === targetValue);
                    if (!matchingBranch) {
                        branches = branches.filter(branch => branch.locationId === allRecordsFilter);
                    } else {
                        branches = [matchingBranch];
                    }
                    this.setState({ ...this.state, 
                        locationFilter: targetValue,
                        locationName,
                        branchFilter: branches[0].name, 
                        currentBranches: branches,
                        currentPayerIds: filteredPayerIds, 
                        taxPayerName: "", 
                        taxPayerId: filteredPayerIds[0].value });
                } else {
                    //Setea todos los filtros por default cuando no se selecciono un filtro especifico.
                    this.setState({
                        ...this.state,
                        locationFilter: targetValue,
                        branchFilter: allRecordsFilter,
                        branchName: "",
                        taxPayerId: allRecordsFilter,
                        currentPayerIds: taxPayerIds,
                        taxPayerName: "",
                        locationName
                    });
                }
                this.props.changeFilter(this.state.taxPayerId, this.state.currentBranches[0].name, this.state.departmentFilter, targetValue, this.state.departmentName, locationName, "", "");
                break;
    
            case targetId.includes("departmentFilter"):
                let locations = WebCache.getAllLocations();
                branches = WebCache.getAllBranches();
                const department = WebCache.getAllDepartments().find(dep => dep.id == targetValue);
                if (targetValue !== allRecordsFilter) {
                    departmentName = department ? department.name : "";
                    const filteredPayerIds = taxPayerIds.filter(payerId =>
                        WebCache.getAllTerminals().some(t => t.clientDocument === payerId.value && t.departmentName === departmentName)
                    ).map(payerId => ({
                        value: payerId.value,
                        label: payerId.value,
                        key: payerId.key,
                        disabled: payerId.disabled
                    }));
                    const matchingLocation = locations.find(location => location.departmentId === targetValue);
                    if (!matchingLocation) {
                        locations = locations.filter(location => location.departmentId === allRecordsFilter);
                    } else {
                        locations = [matchingLocation];
                    }
                    const matchingBranch = branches.find(branch => branch.departmentId === targetValue);
                    if (!matchingBranch) {
                        branches = branches.filter(branch => branch.locationId === allRecordsFilter);
                    } else {
                        branches = [matchingBranch];
                    }
                    this.setState({ ...this.state, departmentFilter: targetValue,
                        currentLocations: locations,
                        locationName: locations[0].name,
                        locationFilter: locations[0].name,
                        branchFilter: branches[0].name,
                        currentBranches: branches,
                        branchName: branches[0].name,
                        taxPayerId: filteredPayerIds[0].value,
                        currentPayerIds: filteredPayerIds,
                        taxPayerName: filteredPayerIds[0].label,
                        departmentName });
                } 
                else {
                    this.setState({
                        ...this.state,
                        departmentFilter: targetValue,
                        locationFilter: allRecordsFilter,
                        currentLocations: locations,
                        locationName: "",
                        branchFilter: allRecordsFilter,
                        currentBranches: branches,
                        branchName: "",
                        taxPayerId: allRecordsFilter,
                        currentPayerIds: taxPayerIds,
                        taxPayerName: "",
                        departmentName
                    });
                }
                this.props.changeFilter(allRecordsFilter, allRecordsFilter, targetValue, allRecordsFilter, departmentName, "", "", "");
                break;
    
            default:
                break;
        }
    }

    
    buildSelectOptionsFromIdName(options: Array<{ id: string, name: string }>) {
        return new OptionMapperHelper(this.props.t).buildSelectOptionsFromAnArrayWithDifferentInterface(options, 'id', 'name');
    }

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let merchantFilterLabel;

        if (currentUser !== undefined && currentUser === 2) {
            merchantFilterLabel = t('merchantDocument');
        } else {
            merchantFilterLabel = t('merchantDocumentArg');
        }
        return (
            <React.Fragment>
                <div className="col-md-3">
                    <SelectField model={[this.state, this.setState.bind(this)]} attr='departmentFilter' label={WebCache.getCurrentUser()?.countryId == 2 ? 'department' : 'departmentArg'} options={this.buildSelectOptionsFromIdName(WebCache.getAllDepartments())} onChange={(e) => this.handleChange(e)}></SelectField>
                </div>
                <div className="col-md-3">
                    <SelectField model={[this.state, this.setState.bind(this)]} attr='locationFilter' label='location' options={this.buildSelectOptionsFromIdName(this.state.currentLocations)} onChange={(e) => this.handleChange(e)}></SelectField>
                </div>
                <div className="col-md-3">
                    <SelectField model={[this.state, this.setState.bind(this)]} attr='branchFilter' label='branch' options={this.buildSelectOptionsFromIdName(this.state.currentBranches)} onChange={(e) => this.handleChange(e)}></SelectField>
                </div>
                <div className="col-md-3">
                    <SelectField model={[this.state, this.setState.bind(this)]} attr='taxPayerFilter' label={merchantFilterLabel} options={this.state.currentPayerIds} onChange={(e) => this.handleChange(e)}></SelectField>
                </div>
            </React.Fragment>
        )
    };
}
export default withTranslation()(TerminalLocation);
